<template>
  <q-dialog v-model="show" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-body1">{{ $t('CheckRegister.register') }}</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-input dense v-model="userid" :placeholder="$t('CheckRegister.qingshuruyonghuming')" />
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-input dense v-model="password" type="password" :placeholder="$t('CheckRegister.qingshuruyongmima')" />
      </q-card-section>
      <q-card-actions align="right" class="text-primary">
        <q-btn flat :label="$t('CheckRegister.register')" @click="login" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import MD5 from 'js-md5';
import AES from "@/assets/js/aes.js";

export default {
  name: '',
  components: {},
  props: {
  },
  setup() {
    return {
      show: ref(false),
      userid: ref(""),
      password: ref("")
    }
  },
  created() {
    if(this.$utils.getloc("loginCount") == 0) {
      this.show = true
    }
  },
  methods: {
    login() {
      let _this = this
      _this.$q.loading.show({
        message: this.$t('CheckRegister.dengluzhong')
      })
      let key = MD5(this.$config.Project).toUpperCase();
			let password = AES.encrypt(this.password, key);
      _this.$request.post(
        "api/Users/Login3",
        {
          address: _this.userid,
          password: password
        },
        (res) => {
          _this.$q.loading.hide();
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg
            })
            return
          }
          let data = res.data.data
          _this.$utils.setloc("token", data.token);
          _this.$utils.setloc("us", JSON.stringify(data.users));
          // _this.$store.commit('setLoginState', true)
          _this.$utils.setloc('loginCount', 1)
          _this.$emit("refresh")
          _this.show = false
        }
      )
    }
  }
}
</script>

<style scoped></style>