<template>
  <div class="home">
    <div class="home-head">
      <HomeHeadBar></HomeHeadBar>
      
      <div class="home-container">
        <div class="home-head-h1">CITADEL</div>
        <div class="home-head-h5">{{ $t('Home.jifaqianneng') }}</div>
        <div class="row">
          <div class="home-head-btn" @click="tolink('Market')">
            {{ $t('Home.jinridapp') }}
            <img src="@/assets/img/base/arrow-black.png" />
          </div>
        </div>
        <div class="home-head-about">
          <div>{{ $t('Home.guanyu') }}</div>
          <div class="home-head-h3">CITADEL</div>
          <div>{{ $t('Home.lianjiequkuai') }},</div>
          <div>{{ $t('Home.zhanxianshequ') }}</div>
        </div>
        <div class="home-head-section">
          {{ $t('Home.sectionmsg1') }}				
        </div>
        <div class="home-head-section">
          {{ $t('Home.sectionmsg2') }}											
        </div>
      </div>
    </div>

    <div class="home-landing">
      <div class="home-container">
        <div class="landing-h2">{{ $t('Home.zhize') }}</div>
        <div class="landing-label q-mt-sm">
					{{ $t('Home.zhizemsg') }}
        </div>
        <div class="q-mt-sm">
          <div class="listing">
            <img src="@/assets/img/base/whatwedo_2.png" width="80" />
            <div class="listing-title q-mt-md">{{ $t('Home.qiye') }}</div>
            <div class="blue-text q-mt-md">{{ $t('Home.qiyemsg1') }}</div>
            <div class="q-mt-sm">{{ $t('Home.qiyemsg2') }}</div>
            <div class="blue-text q-mt-md">{{ $t('Home.qiyemsg3') }}</div>
            <div class="q-mt-sm">{{ $t('Home.qiyemsg4') }}</div>
          </div>
          <div class="listing">
            <img src="@/assets/img/base/whatwedo_1.png" width="80" />
            <div class="listing-title q-mt-md">{{ $t('Home.genzongtitle') }}</div>
            <div class="blue-text q-mt-md">{{ $t('Home.genzonglabel') }}</div>
            <div class="q-mt-sm">{{ $t('Home.genzongmsg1') }}</div>
            <div class="q-mt-sm">{{ $t('Home.genzongmsg2') }}</div>
            <div class="q-mt-sm">{{ $t('Home.genzongmsg3') }}</div>
            <div class="blue-text q-mt-md">{{ $t('Home.genzongmsg4') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="product">
      <div class="landing-h2 text-center">{{ $t('Home.trade') }}</div>
      <div class="text-center text-grey" style="font-size: 16px;">
        <div>{{ $t('Home.tradelable') }}：</div>
        <div>SD3022v1.0 (BSC), SD3022 v2.0 (polygon)</div>
      </div>
      <div class="product-item">
        <div class="product-item-title">{{ $t('Home.chuangjian') }}</div>
        <div>{{ $t('Home.jiaoyisuanfa') }}</div>
      </div>
      <div class="product-item">
        <div class="product-item-title">{{ $t('Home.shiyong') }}</div>
        <div>{{ $t('Home.zhibiao') }}</div>
      </div>
      <div class="product-item">
        <div class="product-item-title">{{ $t('Home.jiedu') }}</div>
        <div>{{ $t('Home.xinhao') }}</div>
      </div>
      <div class="product-trading">
        <img src="@/assets/img/base/chart-p-800.png" style="width: 100%;" />
      </div>
      <div class="product-msg">
        <div>{{ $t('Home.trademsg1') }}</div>
        <div>{{ $t('Home.trademsg2') }}</div>
      </div>
    </div>

    <div class="home-landing2">
      <div class="landing-h2 text-center">{{ $t('Home.qukuaigonglian') }}</div>
      <div class="text-center" style="font-size: 16px;">{{ $t('Home.xiayidai') }}</div>
      <img src="@/assets/img/base/blockchain_1.png" class="full-width" />
      <div class="q-pa-md">
        <div class="landing-h2" style="font-size: 25px;">{{ $t('Home.xinjingji') }}</div>
        <div class="q-mt-sm">
          {{ $t('Home.xinjingjimsg') }}
        </div>
      </div>
      <img src="@/assets/img/base/blockchain_2.png" class="full-width" />
      <div class="q-pa-md">
        <div class="landing-h2" style="font-size: 25px;">{{ $t('Home.kaifgongju') }}</div>
        <div class="q-mt-sm">
          {{ $t('Home.kaifgongjumsg') }}
        </div>
      </div>
    </div>

    <div class="home-landing3">
      <div class="row justify-center">
        <img src="@/assets/img/base/elf_icon_1.svg" width="40" />
        <img src="@/assets/img/base/line.svg" width="28" class="q-mx-md" />
        <img src="@/assets/img/base/elf_icon_2.svg" width="40" />
      </div>
      <img src="@/assets/img/base/section4_mobile.png" class="full-width q-mt-md" />
      <div class="row justify-center">
        <div>
          <div class="landing3-title q-mt-md">{{ $t('Home.zhongxinhua') }}</div>
          <div class="q-mt-md">{{ $t('Home.zhongxinhuazeren') }}</div>
          <ul class="q-ml-lg q-mt-sm" style="list-style:disc">
            <li>{{ $t('Home.zhongxinhuali1') }}</li>
            <li>{{ $t('Home.zhongxinhuali2') }}</li>
            <li>{{ $t('Home.zhongxinhuali3') }}</li>
            <li>{{ $t('Home.zhongxinhuali4') }}</li>
          </ul>
        </div>
      </div>
      <div style="height: 120px;"></div>
      <div class="text-center" style="font-size: 16px;color: #fff;">CITADEL AI</div>
      <div class="landing3-title text-center q-mt-lg">{{ $t('Home.pianzhng') }}</div>
      <div class="q-mt-sm">
        <div class="listing">
          <img src="@/assets/img/base/dao_1.png" width="60" />
          <div class="listing-title q-mt-md">{{ $t('Home.pianzhnglabel1') }}</div>
          <div class="q-mt-sm">{{ $t('Home.pianzhngmsg1') }}</div>
        </div>
        <div class="listing">
          <img src="@/assets/img/base/dao_2.png" width="60" />
          <div class="listing-title q-mt-md">{{ $t('Home.pianzhnglabel2') }}</div>
          <div class="q-mt-sm">{{ $t('Home.pianzhngmsg2') }}</div>
        </div>
        <div class="listing">
          <img src="@/assets/img/base/dao_2.png" width="60" />
          <div class="listing-title q-mt-md">{{ $t('Home.pianzhnglabel3') }}</div>
          <div class="q-mt-sm">{{ $t('Home.pianzhngmsg3') }}</div>
        </div>
        <div class="listing">
          <div class="listing-title q-mt-md">{{ $t('Home.pianzhnglabel4') }}</div>
          <div class="listing-title q-mt-sm">{{ $t('Home.pianzhngmsg4') }}</div>
        </div>
      </div>
    </div>

    <home-swiper />

    <timeline />

    <div class="partner">
      <div class="partner-row">
        <div class="partner-item" @click="openNewUrl('https://www.gate.io/')">
          <img src="@/assets/img/base/scroll-r1.svg" />
        </div>
        <div class="partner-item" @click="openNewUrl('https://blockbank.ai/')">
          <img src="@/assets/img/base/fideum.svg" />
        </div>
        <div class="partner-item" @click="openNewUrl('https://www.aqr.com/')">
          <img src="@/assets/img/base/aqr.png" style="width: 25px;" class="q-mr-xs" />
          <div style="color: #fff;font-size: 12px;">AQR Capital</div>
        </div>
      </div>
      <div class="partner-row" style="margin-top: 30px;">
        <div class="partner-item" @click="openNewUrl('https://kostat.go.kr/anse/')">
          <img src="@/assets/img/base/statistics-korea.png" style="width: 25px;" class="q-mr-xs" />
          <div style="color: #fff;font-size: 12px;">Statistics</div>
        </div>
        <div class="partner-item" @click="openNewUrl('https://www.broadridge.com/')">
          <img src="@/assets/img/base/broadridge.svg" />
        </div>
        <div class="partner-item" @click="openNewUrl('https://alpaca.markets/jp/index.html')">
          <img src="@/assets/img/base/alpaca.png" style="width: 25px;" class="q-mr-xs" />
          <div style="color: #fff;font-size: 12px;">Alpaca</div>
        </div>
      </div>

      <div class="getintouch-list">
        <div class="getintouch-item" @click="telegramClick">
          <div class="getintouch-img-wrap">
            <img src="@/assets/img/base/telegram.png" style="width: 40px;" />
          </div>
          <div class="getintouch-item-label">Telegram</div>
        </div>
        <div class="getintouch-item" @click="openNewUrl('https://x.com/samsungai_')">
          <div class="getintouch-img-wrap">
            <img src="@/assets/img/base/twitter.png" style="width: 40px;" />
          </div>
          <div class="getintouch-item-label">Twitter</div>
        </div>
      </div>
    </div>

    <div class="kefu" @click="showKefu = true">
      <img src="@/assets/img/base/kf.svg" class="kefu-img" />
    </div>

    <normal-login></normal-login>

    <!-- 公告 -->
		<q-dialog v-model="showNotice" persistent>
			<q-card class="card">
				<div class="card-title">{{ $t('Home.gonggao') }}</div>
				<q-card-actions>
					<div class="card-content" v-html="notice"></div>
				</q-card-actions>
				
				<q-card-actions align="right">
						<q-btn flat label="OK" color="primary" @click="showNotice = false" />
					</q-card-actions>
			</q-card>
		</q-dialog>

    <!-- 客服 -->
		<q-dialog v-model="showKefu" persistent>
			<q-card class="card">
				<div class="card-title">{{ $t('Home.kefu') }}</div>
				<q-card-actions>
					<div class="card-content" v-html="kefuContent"></div>
				</q-card-actions>
				
				<q-card-actions align="right">
						<q-btn flat label="OK" color="primary" @click="showKefu = false" />
					</q-card-actions>
			</q-card>
		</q-dialog>

    <div class="footer">© 2024 CITADEL All Rights Reserved </div>
  </div>
</template>

<script>
import HomeHeadBar from './components/HomeHeadBar.vue'
import Timeline from './components/Timeline.vue'
import HomeSwiper from './components/HomeSwiper.vue'
import NormalLogin from "@/components/NormalLogin"
import { ref } from 'vue'

export default {
  components: {
    HomeHeadBar,
    Timeline,
    HomeSwiper,
    NormalLogin
  },
  setup() {
    return {
      showNotice: ref(false),
      notice: ref(""),
      showKefu: ref(false),
      kefuContent: ref("")
    }
  },
  created() {
    this.getNotice()
    this.getKefu()
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    openNewUrl(url) {
      window.open(url)
    },
    telegramClick() {
      let language = this.$utils.getloc("language")
      let url = ''
      switch(language) {
        case 'zh': 
          url = 'https://t.me/SamsungAI1'
          break
        case 'kor': 
          url = 'https://t.me/SamsungAI4'
          break
        // case 'may': 
        //   url = ''
        //   break
        case 'vie': 
          url = 'https://t.me/SamsungAI3'
          break
        // case 'th': 
        //   url = ''
        //   break
        // case 'id': 
        //   url = ''
        //   break
        default:
          url = 'https://t.me/SamsungAI5'
      }
      this.openNewUrl(url)
    },
    getKefu() {
      let _this = this
      _this.$request.post(
				"api/Article/Get",
				{
					select_id: 20
				},
				(res) => {
					if (res.data.code == 100) {
						let data = res.data.data
            _this.kefuContent = data.articlecontent
					}
				}
			)
    },
    // 获取首页公告
		getNotice() {
			let _this = this
			let language = this.$utils.getloc("language")
			let select_id = 14
			if(language == 'zh') {
				select_id = 13
			} else if(language == 'kor') {
				select_id = 15
			} else if(language == 'may') {
				select_id = 17
			} else if(language == 'vie') {
				select_id = 16
			} else if(language == 'th') {
				select_id = 18
			} else {
				select_id = 14
			}
			_this.$request.post(
				"api/Article/Get",
				{
					select_id: select_id
				},
				(res) => {
					if (res.data.code == 0) {
						_this.$q.dialog({
							message: res.data.msg,
						});
						return
					}
					let data = res.data.data
					_this.notice = data.articlecontent
					if(_this.notice) {
						_this.showNotice = true
					}
				}
			)
		},
  }
}
</script>

<style scoped>
.home-head {
  background: url('~@/assets/img/base/home-header.png');
  background-size: 100% 100%;
}

.home-container {
  padding: 20px 12px 40px;
  color: #fff;
}

.home-head-h1 {
  color: #00ecf6;
  font-size: 54px;
  font-weight: bold;
}

.home-head-h5 {
  margin-bottom: 32px;
  font-size: 18px;
}

.home-head-btn {
  padding: 18px;
  display: flex;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  border-radius: 99px;
  background-color: #00ecf6;
}

.home-head-btn img {
  margin-left: 10px;
  width: 24px;
}

.home-head-about {
  padding: 60px 0 40px;
  text-align: right;
}

.home-head-h3 {
  padding: 10px 0 18px;
  font-size: 32px;
  font-weight: bold;
}

.home-head-section {
  padding: 16px;
  font-size: 12px;
  border-radius: 10px;
  background: linear-gradient(134deg, #227AFF 0%, #0012B9 100%);
}

.home-head-section:last-child {
  margin-top: 20px;
  background: linear-gradient(158deg, #0E1D3F 0%, #0E1D3F 100%);
}

.home-landing {
  padding: 170px 0 0;
  background-color: #fff;
  background-image: url('~@/assets/img/base/home-section1.png');
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: top right;
}

.landing-h2 {
  color: #002678;
  font-weight: bold;
  font-size: 28px;
}

.landing-label {
  font-size: 12px;
  color: #828599;
}

.listing {
  margin-top: 20px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #fff;
  box-shadow: 0px 16px 60px rgba(0,40,155,.1);
  border-radius: 20px;
  color: #828599;
  font-size: 12px;
}

.listing-title {
  font-size: 15px;
  color: #002678;
}

.blue-text {
  color: #002678;
}

.home-landing2 {
  padding: 100px 0 40px;
  background-color: #fff;
  background-image: url('~@/assets/img/base/landing-bg.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 35%;
  color: #828599;
  font-size: 12px;
}

.home-landing3 {
  padding: 0 12px;
  background-color: #fff;
  background-image: url('~@/assets/img/base/home-landing3.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  color: #828599;
  font-size: 12px;
}

.landing3-title {
  font-size: 23px;
  font-weight: bold;
  color: #fff;
}

.home-landing3 .listing{
  padding: 30px 24px;
  background-color: rgba(255,255,255,.05);
}

.home-landing3 .listing-title {
  color: #fff;
}

.home-landing3 .listing:last-child {
  padding: 20px;
  background: url('~@/assets/img/base/listing-bg.png') no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
}

.footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  background-color: #080f24;
}

.partner {
  padding: 10px 0 30px;
  background-color: #0C1530;
}

.partner-row {
  padding: 0 6px;
  position: relative;
  display: flex;
  align-items: center;
}

.partner-item {
  margin: 0 5px;
  width: 30%;
  height: 40px;
  border-radius: 16px;
  border: 1px solid transparent;
  background-image: linear-gradient(180deg,#080f24,#4dd2d6),linear-gradient(180deg,rgba(17,118,177,.4),rgba(17,118,177,0));
  background-origin: border-box;
  background-clip: padding-box,border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner img {
  width: 80px;
}

.product {
  padding: 15px 0;
  background-color: #fff;
}

.product-item {
  position: relative;
  margin: 15px 20px;
  padding: 30px 0;
  font-size: 16px;
  text-align: center;
  background-color: #f3f8fd;
  z-index: 2;
}

.product-item-title {
  margin-bottom: 10px;
  font-weight: bold;
  color: #002678;
  font-size: 18px;
}

.product-trading {
  margin-top: -40px;
}

.product-msg {
  padding: 15px;
  color: #828599;
  font-size: 13px;
}

.card {
	width: 350px;
}

.card-content {
	padding: 0 10px;
	max-height: 400px;
	overflow-y: auto;
}

.card-title {
	padding: 12px 8px 8px;
	font-weight: bold;
	text-align: center;
	font-size: 18px;
}

.kefu {
  padding: 3px;
  position: fixed;
  right: 10px;
  bottom: 20px;
  z-index: 9;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.kefu-img {
  display: block;
  width: 36px;
  height: 36px;
}

.getintouch-list {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.getintouch-item {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.getintouch-img-wrap {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.getintouch-item:nth-child(1) .getintouch-img-wrap {
  background-color: #0b3470;
}

.getintouch-item:nth-child(2) .getintouch-img-wrap {
  background-color: #484972;
}

.getintouch-item-label {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  color: #fff;
}
</style>